import { httpPost, httpPostAuth, ApiNames, httpGetByUrl, httpGetAuth } from './httpHelper'
import { i18n } from './../main.js'
import { httpGetShopUrl } from './shopUrlGeneration'

export async function sendEmailConfimation(token, emailReceiver, isRoloffMatek, firstName, lastName) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''
  if (isRoloffMatek) {
    token = token + '&isRoloffMatek'
  }
  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const customConfigEmail = {
    headers: {
      'Content-Type': "application/json; charset='UTF-8'",
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('registerMailTemplate'))
    if (vorlage?.status === 200 && vorlage?.data) {
      var link = window.origin + '/confirmationRegistration?id=' + token
      var bodyText = vorlage.data
        .replace('%confirmlink%', link)
        .replace('%firstname%', firstName)
        .replace('%lastname%', lastName)
      var mailJson = {
        receiver: emailReceiver, // + marketing-services@mdesign.de,//document.getElementById("receiver").value.trim(),
        cc: '', //document.getElementById("cc").value.trim(),
        sender: 'no-reply@mdesign.de', //document.getElementById("sender").value.trim(),
        senderName: 'MyMDESIGN', // document.getElementById("senderName").value.trim(),
        body: bodyText, //document.getElementById("body").value,
        subject: i18n.t('subjectRegistrationEmail'), //document.getElementById("subject").value.trim(),
        attachments: [],
        blindCC: '', //document.getElementById("blindCC").value.trim(),
        html: true, // document.getElementById("html").checked,
        mailer: 'noreply', //document.getElementById("mailer").value.trim(),
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
    }
  }
  return emailSent
}
export async function sendEmailConfimationAnonym(token, emailReceiver) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''

  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const customConfigEmail = {
    headers: {
      'Content-Type': "application/json; charset='UTF-8'",
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('registerMailTemplateAnonym'))
    if (vorlage?.status === 200 && vorlage?.data) {
      var link = window.origin + '/confirmationRegistration?id=' + token
      var bodyText = vorlage.data.replace('%confirmlink%', link)
      var mailJson = {
        receiver: emailReceiver, // + marketing-services@mdesign.de,//document.getElementById("receiver").value.trim(),
        cc: '', //document.getElementById("cc").value.trim(),
        sender: 'no-reply@mdesign.de', //document.getElementById("sender").value.trim(),
        senderName: 'MyMDESIGN', // document.getElementById("senderName").value.trim(),
        body: bodyText, //document.getElementById("body").value,
        subject: i18n.t('subjectRegistrationEmail'), //document.getElementById("subject").value.trim(),
        attachments: [],
        blindCC: '', //document.getElementById("blindCC").value.trim(),
        html: true, // document.getElementById("html").checked,
        mailer: 'noreply', //document.getElementById("mailer").value.trim(),
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
    }
  }
  return emailSent
}
export async function sendEmailResetPassword(emailReceiver) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''
  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))
  var objEmailReciever = {
    Email: emailReceiver,
  }
  var base64Reciever = btoa(JSON.stringify(objEmailReciever))
  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  var responseToken = await httpPost(ApiNames.createtoken, base64Reciever, customConfig)
  if (responseToken?.data.Message === 'user is not exists.') {
    return responseToken.data.Message
  }
  if (response?.status === 200 && response?.data) {
    const getContactData = await httpGetAuth(
      ApiNames.contactbyemail,
      {
        email: emailReceiver,
      },
      {},
      response.data,
    )
    const vorlage = await httpGetByUrl(i18n.t('resetPasswordMailTemplate'))
    if (vorlage?.status === 200 && vorlage?.data && responseToken?.status === 200 && responseToken?.data) {
      var link = window.origin + '/setNewPassword?id=' + responseToken.data
      var bodyText = vorlage.data
        .replace('%resetlink%', link)
        .replace('%firstname%', getContactData.data[0].FirstName)
        .replace('%lastname%', getContactData.data[0].LastName)

      var mailJson = {
        receiver: emailReceiver, // + marketing-services@mdesign.de,//document.getElementById("receiver").value.trim(),
        cc: '', //document.getElementById("cc").value.trim(),
        sender: 'no-reply@mdesign.de', //document.getElementById("sender").value.trim(),
        senderName: 'MyMDESIGN', // document.getElementById("senderName").value.trim(),
        body: bodyText, //document.getElementById("body").value,
        subject: i18n.t('resetPasswordEmail'), //document.getElementById("subject").value.trim(),
        attachments: [],
        blindCC: '', //document.getElementById("blindCC").value.trim(),
        html: true, // document.getElementById("html").checked,
        mailer: 'noreply', //document.getElementById("mailer").value.trim(),
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
    }
  }
  return emailSent
}
export async function sendEmailApproveStudentImo(contactEmail, downloadFile, fileName) {
  const emailReceiver = 'simon.kissling@tedata.de, hotline@mdesign.de'

  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''
  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)

  let mailJson
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('approveStudentImaByHotline'))
    var bodyText = vorlage.data.replaceAll('&user&', contactEmail)

    const createEmail = async () => {
      const reader = new FileReader()

      try {
        // Wrap the FileReader operation in an async function
        const readBlobContent = () =>
          new Promise((resolve, reject) => {
            // Define the onload callback
            reader.onload = function () {
              try {
                const attachmentData = reader.result.split(',')[1] // extract base64-encoded data
                mailJson = {
                  receiver: emailReceiver,
                  cc: '',
                  sender: 'no-reply@mdesign.de',
                  senderName: 'MyMDESIGN',
                  body: bodyText,
                  subject: 'Immatrikulationsbescheinigung prüfen / check matriculation certificate',
                  attachments: [
                    {
                      name: fileName,
                      content: attachmentData,
                    },
                  ],
                  blindCC: '',
                  html: true,
                  mailer: 'noreply',
                }

                // Now you can use the 'mailJson' object as needed

                // Resolve with the attachment data
                resolve(attachmentData)
              } catch (error) {
                reject(error)
              }
            }

            // Read the Blob content
            reader.readAsDataURL(downloadFile)
          })

        // Use await to get the result of the asynchronous readBlobContent
        const attachmentData = await readBlobContent()

        // Proceed with sending the email
        const mailJsonString = JSON.stringify(mailJson)
        const base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
        const headerConfig = {
          headers: { Authorization: `Bearer ${response.data}` },
        }

        // Use await to get the result of the asynchronous httpPost
        const sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)

        if (sendResp?.status === 200 && sendResp?.data) {
          emailSent = sendResp.data
        } else {
          emailSent = false
        }
      } catch (error) {}
    }

    // Call the createEmail function
    await createEmail()
    return emailSent
  }
}
export async function sendEmailConfirmStudentEmail(token, emailReceiver, firstName, lastName) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''

  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))
  var emailCoded = btoa(emailReceiver)
  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('confimUniMailBody'))
    if (vorlage?.status === 200 && vorlage?.data) {
      var link = window.origin + '/confirmationStudentEmail?id=' + token + '&email=' + emailCoded
      var bodyText = vorlage.data
        .replace('%confirmlink%', link)
        .replace('%firstname%', firstName)
        .replace('%lastname%', lastName)
      var mailJson = {
        receiver: emailReceiver, // + marketing-services@mdesign.de,//document.getElementById("receiver").value.trim(),
        cc: '', //document.getElementById("cc").value.trim(),
        sender: 'no-reply@mdesign.de', //document.getElementById("sender").value.trim(),
        senderName: 'MyMDESIGN', // document.getElementById("senderName").value.trim(),
        body: bodyText, //document.getElementById("body").value,
        subject: i18n.t('subjectStudentEmail'), //document.getElementById("subject").value.trim(),
        attachments: [],
        blindCC: '', //document.getElementById("blindCC").value.trim(),
        html: true, // document.getElementById("html").checked,
        mailer: 'noreply', //document.getElementById("mailer").value.trim(),
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
    }
  }
  return emailSent
}
/////
export async function sendEmailApproveStudentEmailToHotline(contactEmail) {
  //const emailReceiver = 'ievgen.nikiforov@tedata.de'
  const emailReceiver = 'simon.kissling@tedata.de'
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''
  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)

  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('approveStudentEmailByHotline'))
    var bodyText = vorlage.data.replaceAll('&email&', contactEmail)
    var mailJson = {
      receiver: emailReceiver,
      cc: '',
      sender: 'no-reply@mdesign.de',
      senderName: 'MyMDESIGN',
      body: bodyText,
      subject: 'Studenten-E-Mail prüfen / check student email',

      blindCC: '',
      html: true,
      mailer: 'noreply',
    }
    const mailJsonString = JSON.stringify(mailJson)
    var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
    const headerConfig = {
      headers: { Authorization: `Bearer ${response.data}` },
    }
    var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
    if (sendResp?.status == 200 && sendResp?.data) {
      // emailSent = true;
      emailSent = sendResp.data
    } else {
      emailSent = 'err'
    }
  }

  return emailSent
}
export async function sendEmailAboutStatusChangeToStudent(
  companyID,
  productID,
  contactID,
  contactEmail,
  token,
  newStatus,
  firstName,
  lastName,
) {
  const emailReceiver = contactEmail
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''
  var obj = {
    Email: email,
    Password: password,
  }
  let bodyText = {
    1: i18n.t('studentProofExpiredEmailBody'),
    3: i18n.t('studentIsVerifiedBodyPayment'),
    5: i18n.t('uploadIMARequestEmailBody'),
    7: i18n.t('uploadNewImaRequestEmailBody'),
    8: i18n.t('studentIsVerifiedBodyNoPaymentBody'),
  }
  let title = {
    1: i18n.t('studentProofExpiredEmailTitle'),
    3: i18n.t('studentIsVerifiedPaymentTitle'),
    5: i18n.t('uploadIMARequestEmailTitle'),
    7: i18n.t('uploadNewImaRequestEmailTitle'),
    8: i18n.t('studentIsVerifiedNoPaymentTitle'),
  }
  let linkToShop
  if (newStatus == 3) {
    const getShopUrlResponse = await httpGetShopUrl(companyID, productID, contactID, contactEmail, token)
    linkToShop = getShopUrlResponse.data
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)

  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(bodyText[newStatus])
    var loginLink = window.origin + '/login'
    var bodyTextReplace = vorlage.data.replace('%firstname%', firstName).replace('%lastname%', lastName)
    if (bodyTextReplace.includes('%loginlink%') || bodyTextReplace.includes('%mloginlink%')) {
      bodyTextReplace = bodyTextReplace.replace('%loginlink%', loginLink)
      bodyTextReplace = bodyTextReplace.replace('%mloginlink%', loginLink)
    }
    var mailJson = {
      receiver: emailReceiver,
      cc: '',
      sender: 'no-reply@mdesign.de',
      senderName: 'MyMDESIGN',
      body: bodyTextReplace,
      subject: title[newStatus],

      blindCC: '',
      html: true,
      mailer: 'noreply',
    }
    const mailJsonString = JSON.stringify(mailJson)
    var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
    const headerConfig = {
      headers: { Authorization: `Bearer ${response.data}` },
    }
    var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
    if (sendResp?.status == 200 && sendResp?.data) {
      // emailSent = true;
      emailSent = sendResp.data
    } else {
      emailSent = 'err'
    }
  }

  return emailSent
}
export async function sendEmailTfaAuthentication(code, firstname, lastname, emailreceiver) {
  // const emailReceiver = 'simon.kissling@tedata.de'
  const emailReceiver = emailreceiver

  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var link = window.origin + '/entertfacode?code=' + code

  var emailSent = ''
  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('tfaAuthMailBody'))

    var bodyText = vorlage.data

    bodyText = bodyText.replace('%code%', code)
    bodyText = bodyText.replace('%firstname%', firstname)
    bodyText = bodyText.replace('%lastname%', lastname)
    // bodyText = bodyText.replace('%codelink%', link)

    var mailJson = {
      receiver: emailReceiver,
      cc: '',
      sender: 'no-reply@mdesign.de',
      senderName: 'MyMDESIGN',
      body: bodyText,
      subject: i18n.t('subjectTfa'),

      blindCC: '',
      html: true,
      mailer: 'noreply',
    }
    const mailJsonString = JSON.stringify(mailJson)
    var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
    const headerConfig = {
      headers: { Authorization: `Bearer ${response.data}` },
    }
    var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
    if (sendResp?.status == 200 && sendResp?.data) {
      // emailSent = true;
      emailSent = sendResp.data
    } else {
      emailSent = 'err'
    }
  }

  return emailSent
}

export async function sendEmailResetLockout(emailreceiver, token, firstname, lastname) {
  // const emailReceiver = 'simon.kissling@tedata.de'
  const emailReceiver = emailreceiver

  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'

  var tokenStr = encodeURIComponent(token)

  var link = window.origin + '/resetlockout?token=' + tokenStr

  var emailSent = ''
  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('resetLockoutMailBody'))

    var bodyText = vorlage.data

    bodyText = bodyText.replace('%mloginlink%', link)
    bodyText = bodyText.replace('%firstname%', firstname)
    bodyText = bodyText.replace('%lastname%', lastname)
    // bodyText = bodyText.replace('%codelink%', link)

    var mailJson = {
      receiver: emailReceiver,
      cc: '',
      sender: 'no-reply@mdesign.de',
      senderName: 'MyMDESIGN',
      body: bodyText,
      subject: i18n.t('subjectAccountLocked'),

      blindCC: '',
      html: true,
      mailer: 'noreply',
    }
    const mailJsonString = JSON.stringify(mailJson)
    var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
    const headerConfig = {
      headers: { Authorization: `Bearer ${response.data}` },
    }
    var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
    if (sendResp?.status == 200 && sendResp?.data) {
      // emailSent = true;
      emailSent = sendResp.data
    } else {
      emailSent = 'err'
    }
  }

  return emailSent
}

export async function sendEmailConfirmationCompanyContact(token, contact) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''

  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const customConfigEmail = {
    headers: {
      'Content-Type': "application/json; charset='UTF-8'",
    },
  }
  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('registerMailTemplate'))
    if (vorlage?.status === 200 && vorlage?.data) {
      var contactCrypt = btoa(contact.ID)
      var link = window.origin + '/confirmcompanycontact?id=' + contactCrypt + '&token=' + token

      var bodyText = vorlage.data
        .replace('%confirmlink%', link)
        .replace('%firstname%', contact.FirstName)
        .replace('%lastname%', contact.LastName)
      var mailJson = {
        receiver: contact.Email, // + marketing-services@mdesign.de,//document.getElementById("receiver").value.trim(),
        cc: '', //document.getElementById("cc").value.trim(),
        sender: 'no-reply@mdesign.de', //document.getElementById("sender").value.trim(),
        senderName: 'MyMDESIGN', // document.getElementById("senderName").value.trim(),
        body: bodyText, //document.getElementById("body").value,
        subject: i18n.t('subjectRegistrationEmail'), //document.getElementById("subject").value.trim(),
        attachments: [],
        blindCC: '', //document.getElementById("blindCC").value.trim(),
        html: true, // document.getElementById("html").checked,
        mailer: 'noreply', //document.getElementById("mailer").value.trim(),
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
    }
  }
  return emailSent
}

export async function sendEmailConfirmationNewsLetter(salutation, firstName, lastName, propmail, type = 'student') {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''

  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  const customConfigEmail = {
    headers: {
      'Content-Type': "application/json; charset='UTF-8'",
    },
  }
  var base64 = btoa(JSON.stringify(obj))
  const letterTemplate = type === 'student' ? 'newsletterMailTemplate' : 'newsletterMailTemplateForRM'
  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t(letterTemplate))
    if (vorlage?.status === 200 && vorlage?.data) {
      var bodyText = vorlage.data
        .replace('%anrede%', salutation)
        .replace('%firstname%', firstName)
        .replace('%lastname%', lastName)
        .replace('%E-Mail%', propmail)
      const emailSubject =
        type === 'student'
          ? 'Aktivierung für MDESIGN 2024 Studienversion Newsletter'
          : 'Aktivierung für Roloff/Matek Newsletter'
      var mailJson = {
        receiver: 'info@newsletter-mdesign.de', //''
        cc: '', //'denis.polyakov@tedata.de',
        sender: 'no-reply@mdesign.de',
        senderName: 'MyMDESIGN',
        body: bodyText,
        subject: emailSubject,
        attachments: [],
        blindCC: '',
        html: true,
        mailer: 'noreply',
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
    }
  }
  return emailSent
}
export async function sendEmailStudent2024PaymentRecieved(firstName, lastName, emailReceiver) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''

  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const vorlage = await httpGetByUrl(i18n.t('paymentIsRecievedBody'))
    if (vorlage?.status === 200 && vorlage?.data) {
      var bodyTextReplace = vorlage.data.replace('%firstname%', firstName).replace('%lastname%', lastName)
      var loginLink = window.origin + '/login'
      if (bodyTextReplace.includes('%loginlink%') || bodyTextReplace.includes('%mloginlink%')) {
        bodyTextReplace = bodyTextReplace.replace('%loginlink%', loginLink)
        bodyTextReplace = bodyTextReplace.replace('%mloginlink%', loginLink)
      }
      var mailJson = {
        receiver: emailReceiver,
        cc: '',
        sender: 'no-reply@mdesign.de',
        senderName: 'MyMDESIGN',
        body: bodyTextReplace,
        subject: i18n.t('paymentIsRecievedTitle'),
        attachments: [],
        blindCC: '',
        html: true,
        mailer: 'noreply',
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
    }
  }
  return emailSent
}

export async function sendEmailWithLicense(emailReceiver, license, version, fileName, comment, cc, lang, subject) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''

  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }

  var base64 = btoa(JSON.stringify(obj))

  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
    const templateLink =
      parseInt(version) < 19 ? i18n.t('sendLicenseMailBody_v86', lang) : i18n.t('sendLicenseMailBody_v64', lang)
    let vorlage = await httpGetByUrl(templateLink)
    let bodyText = vorlage.data.replace('&starscount&', i18n.t('licencesFor')).replace('%comment%', comment)
    if (vorlage?.status === 200 && vorlage?.data) {
      const createEmail = async () => {
        const reader = new FileReader()
        let mailJson
        try {
          // Wrap the FileReader operation in an async function
          const readBlobContent = () =>
            new Promise((resolve, reject) => {
              // Define the onload callback
              reader.onload = function () {
                try {
                  const attachmentData = reader.result.split(',')[1] // extract base64-encoded data
                  mailJson = {
                    receiver: emailReceiver,
                    cc: cc,
                    sender: 'no-reply@mdesign.de',
                    senderName: 'MyMDESIGN',
                    body: bodyText,
                    subject: subject,
                    attachments: [
                      {
                        name: fileName,
                        content: attachmentData,
                      },
                    ],
                    blindCC: '',
                    html: true,
                    mailer: 'noreply',
                  }

                  // Now you can use the 'mailJson' object as needed

                  // Resolve with the attachment data
                  resolve(attachmentData)
                } catch (error) {
                  reject(error)
                }
              }

              // Read the Blob content
              reader.readAsDataURL(license)
            })

          // Use await to get the result of the asynchronous readBlobContent
          const attachmentData = await readBlobContent()

          // Proceed with sending the email
          const mailJsonString = JSON.stringify(mailJson)
          const base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
          const headerConfig = {
            headers: { Authorization: `Bearer ${response.data}` },
          }

          // Use await to get the result of the asynchronous httpPost
          const sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)

          if (sendResp?.status === 200 && sendResp?.data) {
            emailSent = sendResp.data
          } else {
            emailSent = false
          }
        } catch (error) {}
      }

      // Call the createEmail function
      await createEmail()
      return emailSent
    }
  }
}
export async function deleteEmailFromVemas(contactToDelete) {
  var email = 'mailsender@tedata.de'
  var password = 'T9uECy$a4CJz&Wv4'
  var emailSent = ''
  var obj = {
    Email: email,
    Password: password,
  }
  const customConfig = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  var base64 = btoa(JSON.stringify(obj))
  var response = await httpPost(ApiNames.authorization, base64, customConfig)
  if (response?.status === 200 && response?.data) {
      var bodyText = 'Email: '+contactToDelete
      const emailSubject = 'Löschung des Kontakts'
      var mailJson = {
        receiver: 'info@newsletter-mdesign.de',
        cc: '', //'denis.polyakov@tedata.de',
        sender: 'no-reply@mdesign.de',
        senderName: 'MyMDESIGN',
        body: bodyText,
        subject: emailSubject,
        attachments: [],
        blindCC: '',
        html: true,
        mailer: 'noreply',
      }
      const mailJsonString = JSON.stringify(mailJson)
      var base64MailJson = window.btoa(unescape(encodeURIComponent(mailJsonString)))
      const headerConfig = {
        headers: { Authorization: `Bearer ${response.data}` },
      }
      var sendResp = await httpPost(ApiNames.sendemail, base64MailJson, headerConfig)
      if (sendResp?.status == 200 && sendResp?.data) {
        // emailSent = true;
        emailSent = sendResp.data
      } else {
        emailSent = 'err'
      }
  }
  return emailSent
}