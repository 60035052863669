<template>
  <div class="md-layout scroll-behavior mdesign-overview-content">
    <div class="loader-wrapper">
      <loader :loader="loader" />
    </div>
    <modal v-if="showModalConfig" @close="closeModal" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('askProceed') }}</h4>
      </template>
      <template slot="footer">
        <div class="md-layout">
          <div class="md-layout-item md-size-50 just-center">
            <md-button @click="toTemplates" class="md-round md-primary">{{ $t('withTemplates') }}</md-button>
          </div>
          <div class="md-layout-item md-size-50 just-center">
            <md-button @click="toProducts" class="md-round md-primary">{{ $t('withProducts') }}</md-button>
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="md-layout modal-content">
          <div class="md-layout-item md-small-size-100"></div>
        </div>
      </template>
    </modal>

    <modal @close="closeModal" v-if="showModalCopyConfig" class="modal-content" name="copy-config-modal">
      <template v-slot:header>
        <h3 class="modal-title">{{ $t('chooseConfiguration') }}</h3>
      </template>
      <template slot="footer"> </template>
      <template v-slot:body>
        <div class="md-layout copy-exist-config-content">
          <div class="md-layout-item md-size-100" v-for="(item, index) in usableConfigs" :key="index">
            <md-field v-if="item.ConfigurationID !== 'NULL'" class="modal-product list-row md-custom-field">
              <span @click="copyConfiguration(item)" class="modal-span" type="text" id="">{{
                `${productNames[item.ProductID]} ${item.Info}`
              }}</span>
            </md-field>

            <div class="" v-if="usableConfigs.length === 0">{{ $t('noUsableConfigs') }}</div>
          </div>
          <div class="md-layout-item md-size-100 text-right">
            <md-button class="md-primary md-round" @click="closeModal">{{ $t('cancel') }}</md-button>
          </div>
        </div>
      </template>
    </modal>

    <modal :modalWidth="'600'" v-if="showModalDescription" class="modal-content" name="template-modal">
      <template v-slot:header>
        <h4 class="modal-title">{{ $t('infoText') }}</h4>
      </template>
      <template slot="footer">
        <div class="space-evenly">
          <md-button @click="updateConfiguration" class="md-round md-primary" :disabled="getTooLong">{{
            $t('save')
          }}</md-button>
          <md-button @click="closeModal" class="md-round md-simple">{{ $t('cancel') }}</md-button>
        </div>
      </template>
      <template v-slot:body>
        <div class="md-layout modal-content">
          <div class="md-layout-item md-size-100">
            <div class="md-layout-item md-small-size-100">
              <md-field class="has-count-textarea" :class="{ 'too-long': getTooLong }">
                <label>{{ $t('name') }}</label>
                <md-input v-model="infoName" type="text" id="infoName" maxlength="30"></md-input>
              </md-field>
            </div>
            <div class="md-layout-item md-small-size-100">
              <md-field class="has-count-textarea" :class="{ 'too-long': getTooLong }">
                <label>{{ $t('description') }}</label>
                <md-textarea v-model="infoText" type="text" id="infoText" maxlength="222"></md-textarea>
              </md-field>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <div class="md-layout">
      <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
        <div class="product-info center">
          <md-card class="product-info-card">
            <md-card-header class="md-card-header-icon md-card-header-primary">
              <div class="card-icon">
                <md-icon>info</md-icon>
              </div>
              <h4>{{ $t('productInfo') }}</h4>
              <h3 class="">{{ configurationProductLevelStore.product.Title }}</h3>
            </md-card-header>
            <md-card-content>
              <div class="">
                <div class="md-layout">
                  <div class="md-layout-item md-size-100 pb-10" v-if="allow('{43b096e1-db56-4bbc-abcf-627cc1a8e1d9}')">
                    <!-- <md-field> -->
                    <div class="nav-name">
                      <h5 @click="toProfile()" class="mt-0">{{ companyStore.company.Name }}</h5>
                    </div>
                    <!-- </md-field> -->
                  </div>
                  <div class="md-layout-item md-size-100">
                    <md-field class="md-space-between">
                      <span>{{ activeCompanyHasProduct.Info }}</span>

                      <div class="icon-container" v-if="allow('{d7a9f64c-3155-4e96-aef7-c1869051b1d7}')">
                        <span class="animated-icon" @click="handleEditInfo()">edit</span>
                        <md-tooltip>{{ $t('edit') }}</md-tooltip>
                      </div>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-100">
                    {{ configurationProductLevelStore.product.Subtitle }}
                  </div>
                  <div class="md-layout-item md-size-100">
                    {{ configurationProductLevelStore.product.Teaser }}
                  </div>
                </div>
                <div
                class="md-layout-item just-right"
                @drop.prevent="onFileChange"
                @dragover.prevent="handleDragOver"
                @dragleave="handleDragLeave"
               >
                <md-button class="md-primary md-round md-fileinput"
                  >{{ $t('loadLicFile') }}
                  <input accept=".lic" type="file" @change="onFileChange" />
                </md-button>
              </div>
              </div>
            </md-card-content>
          </md-card>
        </div>
      </div>
      <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
        <mdesign-configuration
          :key="renderKey"
          :propConfiguration="selectedConfiguration"
          v-if="selectedConfiguration !== null"
        ></mdesign-configuration>
      </div>

      <div
        v-if="allow('{60d57c5c-bd17-405c-9e1e-290038937b07}')"
        class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100"
      >
        <md-card class="md-card-configurations-overview">
          <md-card-header class="md-card-header-icon md-card-header-primary">
            <div class="card-icon">
              <md-icon>list</md-icon>
            </div>
            <h4>{{ $t('configurations') }}</h4>
          </md-card-header>
          <md-card-content>
            <div v-if="configurations.length === 0" class="md-layout">
              <md-field>
                <div class="md-layout-item md-size-100 md-space-between mh-34-5">
                  <span>{{ $t('noConfigurations') }}</span>
                </div>
              </md-field>
            </div>
            <div v-if="allow('{60d57c5c-bd17-405c-9e1e-290038937b07}')" class="md-layout" :key="renderKey">
              <div class="md-layout-item md-size-100 md-space-between config-content">
                <md-field class="md-custom-field">
                  <div class="col-container col-header col-info">
                    <span>{{ $t('info') }}</span>
                  </div>
                  <div class="col-container"></div>
                  <div class="col-container col-header col-date">
                    <span>{{ $t('date') }}</span>
                  </div>
                  <div class="col-container col-header controls">
                    <span>{{ $t('controls') }}</span>
                  </div>
                </md-field>
              </div>
              <md-field
                v-for="(config, index) in configurations"
                :key="config.ID"
                class="md-custom-field"
                :class="{ 'bg-table': index % 2 !== 0 }"
              >
                <div class="md-layout-item md-size-100 md-space-between config-content">
                  <div class="col-container col-info" :class="{ 'inactive-item': !config.isActive }">
                    <div class="fl-col">
                      <span>{{ getConfigInfoName(config) }}</span>
                      <span class="config-info">{{ getConfigInfo(config) }}</span>
                      <md-tooltip>{{ configTooltips[config.ID] }}</md-tooltip>
                    </div>
                  </div>
                  <div class="col-container"></div>
                  <div class="col-container col-date" :class="{ 'inactive-item': !config.isActive }">
                    <span>{{ formatDate(config.Created.split('T')[0]) }}</span>
                  </div>
                  <div class="controls col-container">
                    <div
                      class="icon-container"
                      v-if="config.isActive && allow('{63de84e0-d489-42d2-afd8-d817b18e8a52}')"
                    >
                      <span class="animated-icon" @click="handleEdit(config)">edit</span>
                      <md-tooltip>{{ $t('edit') }}</md-tooltip>
                    </div>
                    <div class="icon-container" v-else-if="!config.isActive && showControls">
                      <span class="animated-icon" @click="handleEditDescription(config)">edit</span>
                      <md-tooltip>{{ $t('editDescritption') }}</md-tooltip>
                    </div>
                    <div
                      class="icon-container"
                      v-if="!config.isActive && allow('{1c2f6123-6626-48b5-996b-18816c9e1db9}')"
                    >
                      <span @click="handleDelete(config)" class="animated-icon">delete</span>
                      <md-tooltip>{{ $t('delete') }}</md-tooltip>
                    </div>
                    <div class="icon-container">
                      <span @click="handleProtocol(config)" class="animated-icon">visibility</span>
                      <md-tooltip>{{ $t('showProtocol') }}</md-tooltip>
                    </div>
                  </div>
                </div>
              </md-field>
            </div>
            <div v-else class="md-layout">
              <md-field>
                <div class="md-layout-item md-size-100 md-space-between">
                  <span>{{ selectedConfiguration.Info }}</span>
                </div>
              </md-field>
            </div>
            <div class="md-layout-item md-size-100 text-right pt-15">
              

              <div class="pr-10">
                <md-button class="md-simple md-round" @click="handleCopy" :disabled="usableConfigs.length === 0"
                  >{{ $t('choose') }}
                </md-button>
                <md-tooltip>{{ $t('useExistingConfig') }}</md-tooltip>
              </div>

              <md-button
                class="md-primary md-round btn-custom"
                v-if="configurations.length === 0"
                @click="handleCreate"
                :disabled="noMasterConfig"
                >{{ $t('create') }}</md-button
              >
            </div>
          </md-card-content>
        </md-card>
      </div>
      <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100">
        <md-card class="md-card-hostid-file" v-if="isLicencer || allow('{1e63849b-31df-4d00-a883-3a80f39c0c9e}')">
          <md-card-header class="md-card-header-icon md-card-header-primary">
            <div class="card-icon">
              <md-icon>list</md-icon>
            </div>
            <h4 class="">{{ $t('hostIDs') }}</h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <md-field v-if="hostFiles.length === 0">
                <div class="md-layout-item md-size-100 md-space-between mh-34-5">
                  <span>{{ this.$t('noHostFile') }}</span>
                </div>
              </md-field>
            </div>
            <div class="md-layout" v-if="hostFiles.length > 0">
              <md-field class="md-custom-field">
                <div class="md-layout-item md-size-100 md-space-between config-content">
                  <div class="col-container col-header col-info">
                    <span>{{ $t('info') }}</span>
                  </div>

                  <div class="col-container col-header col-mcorpc">
                    <span>{{ $t('mcOrPc') }}</span>
                  </div>

                  <div class="col-container col-header col-date">
                    <span>{{ $t('date') }}</span>
                  </div>
                  <div class="col-container col-header controls">
                    <span>{{ $t('controls') }}</span>
                  </div>
                </div>
              </md-field>
              <md-field v-for="hostFile in hostFiles" :key="hostFile.ID" class="md-custom-field">
                <div
                  class="md-layout-item md-size-100 md-space-between mh-34-5"
                  :class="{ 'inactive-item': hostFile.Disable }"
                >
                  <div class="col-container col-info">
                    <span>{{ hostFile.Comment !== '' ? hostFile.Comment : hostFile.ServerName }}</span>
                  </div>

                  <div class="col-container">
                    <span v-if="hostFile.IsMacAddress">{{ hostFile.MacAddress1 }}</span>
                    <span v-else-if="hostFile.IsPcName">{{ hostFile.PcName1 }}</span>
                    <span v-else-if="hostFile.IsMacAddressAndPcName"
                      >{{ hostFile.MacAddress1 }}<br />{{ hostFile.PcName1 }}</span
                    >
                    <span v-else-if="hostFile.Triade"
                      >{{ hostFile.MacAddress1 }}<br />{{ hostFile.MacAddress2 }}<br />{{ hostFile.MacAddress3 }}</span
                    >
                  </div>

                  <div class="col-container col-date">
                    <span>{{ formatDate(hostFile.Created.split('T')[0]) }}</span>
                  </div>
                  <div class="col-container controls">
                    <div class="icon-container">
                      <span
                        v-if="showControls || allow('{bc3f88f2-ee40-40c9-aff4-e38fedd2dab8}')"
                        class="animated-icon"
                        @click="handleSendLicense(hostFile)"
                        >email</span
                      >
                      <md-tooltip md-direction="bottom">{{ $t('sendLicence') }}</md-tooltip>
                    </div>

                    <div class="icon-container">
                      <span
                        v-if="showControls || allow('{b72f78c1-fe04-40a2-ac81-567d5243ce2d}')"
                        class="animated-icon"
                        @click="handleDownloadLicence(hostFile)"
                        >file_download</span
                      >
                      <md-tooltip>{{ $t('downloadLicenceFile') }}</md-tooltip>
                    </div>
                    <div class="icon-container">
                      <span
                        v-if="showControls || allow('{21d902eb-2022-4553-8140-7fb564abf530}')"
                        class="animated-icon"
                        @click="handleEditHost(hostFile)"
                        >edit</span
                      >
                      <md-tooltip>{{ $t('edit') }}</md-tooltip>
                    </div>
                    <div class="icon-container">
                      <span
                        v-if="showControls || allow('{c0462ad2-a430-4db7-a454-8b535dfd3e20}')"
                        class="animated-icon"
                        :class="{ selected: !hostFile.Disable, 'not-selected': hostFile.Disable }"
                        @click="toggleActive(hostFile)"
                        >noise_control_off</span
                      >
                      <md-tooltip>{{ hostFile.Disable ? $t('activate') : $t('deactivate') }}</md-tooltip>
                    </div>
                  </div>
                </div>
              </md-field>
            </div>
            <div class="md-layout pt-15 btn-container" v-if="allow('{96aa19ab-96e5-4c1f-b258-40a630b00e74}')">
              <div class="md-layout-item md-size-100 text-right">
                <md-button class="md-primary md-round btn-custom" @click="handleCreateHost">{{
                  $t('create')
                }}</md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </div>
    </div>
  </div>
</template>
<script>
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '@/stores/user'
import { useConfigurationProductLevelStore } from '@/stores/configurationProductLevel'
import { useConfigurationStore } from '@/stores/configuration'
import { useManagementStore } from '@/stores/management'
import { sendEmailWithLicense } from '@/libs/emailSender'
import Modal from '../../../components/Modal.vue'
import MdesignConfiguration from './MdesignConfiguration.vue'
import Swal from 'sweetalert2'
import { permissionMixin } from '../../RoleManagement/permissionMixin'
import { extend, validate } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'

extend('required', required)
extend('email', email)

export default {
  mixins: [permissionMixin],
  components: { Modal, MdesignConfiguration },
  data() {
    return {
      selected: {},
      awsAccounts: [],
      loader: false,
      configurations: [],
      showModalConfig: false,
      showModalCopyConfig: false,
      selectedConfiguration: null,
      configurationHistory: [],
      hostFiles: [],
      showControls: true,
      showFullControls: false,
      renderKey: 0,
      imageData: '',
      isLicencer: this.userStore.role.includes('Admin') || this.userStore.role.includes('Licenser'),
      licFileData: null,
      configTooltips: {},
      noMasterConfig: false,
      activeConfigurationID: null,
      companyHasProducts: [],
      productNames: [],
      activeCompanyHasProduct: {},
      infoText: '',
      infoName: '',
      showModalDescription: false,
      configUpdate: {},
      versionProductIDS: {},
      usableConfigs: [],
      dragging: false,
    }
  },
  async beforeMount() {
    this.loader = true
    this.$changePageTitle(this.$t('pageTitleProductOverview'))

    this.configurationProductLevelStore.edit = false
    if (this.userStore.role === 'Administrator') this.showFullControls = true
    if (this.configurationProductLevelStore.isReadonly) this.showControls = false

    if (!this.configurationProductLevelStore.product.ID) this.$router.push({ name: 'Company' })
    await this.getProductConfigurations()

    if (!this.userStore.role.includes('Administrator')) {
      this.configurationProductLevelStore.isReadonly = true
    }

    var resProducts = await this.companyStore.getProducts(this.userStore.token)

    let allProducts = []
    if (resProducts.status === 200) allProducts = resProducts.data

    const product = allProducts.find((el) => el.ID === this.configurationProductLevelStore.activeProduct.ProductID)
    const productsByVersion = allProducts.filter((el) => el.Version === product.Version && el.ID !== product.ID)
    var res = await this.configurationProductLevelStore.getTemplatesByProdHasConfig(this.userStore.token)
    await this.configurationProductLevelStore.getTemplatesByVersion(this.userStore.token, productsByVersion)

    if (res.status === 404) {
      Swal.fire({
        icon: 'error',
        title: this.$t('error'),
        html: this.$t('noConfigrationFound'),
      })
      this.noMasterConfig = true
      this.loader = false
      return
    }

    await this.configurationProductLevelStore.createMasterConfigurationModel(
      this.configurationProductLevelStore.masterModel.ID,
      this.userStore.token,
    )
    if (this.configurations.length > 0) {
      this.configurations.forEach((config) => {
        if (config.isActive) {
          this.selectedConfiguration = config
          this.activeConfigurationID = config.ID
        }
      })
    }
    if (this.selectedConfiguration !== null) this.handleShow(this.selectedConfiguration)

    this.companyHasProducts = this.companyStore.productData.companyHasProduct
    await this.getUsableConfigs(productsByVersion)
    this.activeCompanyHasProduct = this.configurationProductLevelStore.activeProduct

    await this.getHistory()
    await this.getCreatorNames()

    this.renderKey++
    this.loader = false
  },
  methods: {
    async getHistory() {
      this.history = []
      var res = await this.configurationStore.getConfigurationHistoryCompanyHasProduct(
        this.activeCompanyHasProduct.ID,
        this.userStore.token,
      )
      if (res.status !== 200 && res.status !== 404) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('errorGetHistory') + ' ' + res.data.Message,
        })
        return
      }
      this.history = res.data

      if (this.history.length > 0) {
        await Promise.all(
          this.history.map(async (item) => {
            var res = await this.configurationProductLevelStore.getConfiguration(
              item.ConfigurationID,
              this.userStore.token,
            )
            if (res.status === 200) {
              var obj = res.data[0]
              obj.isActive = false
              this.configurations.push(obj)
            }
          }),
        )

        if (this.configurations.length > 1) {
          this.configurations.sort((a, b) => {
            const dateA = new Date(a.Created)
            const dateB = new Date(b.Created)

            return dateB - dateA
          })
        }
      }
    },
    handleEditDescription(config) {
      this.showModalDescription = true
      this.infoName = this.getConfigInfoName(config)
      this.infoText = this.getConfigInfo(config)
      this.configUpdate = { ...config }
    },
    async updateConfiguration() {
      this.showModalDescription = false
      var obj = this.configUpdate
      obj.Info = this.infoName + '/*\\' + this.infoText
      this.loader = true
      var res = await this.configurationStore.updateConfiguration(obj, this.userStore.token)
      await this.getProductConfigurations()
      await this.getHistory()

      this.loader = false
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successUpdateConfig'),
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.data.Message,
        })
      }
      this.infoText = ''
      this.configUpdate = {}
    },
    async handleProtocol(item) {
      this.loader = true

      this.configurationProductLevelStore.protocolData.Version = this.configurationProductLevelStore.product.Version
      this.configurationProductLevelStore.protocolData.ConfigurationID =
        this.configurationProductLevelStore.activeProduct.ConfigurationID
      const versionType = this.configurationProductLevelStore.product.Title
      if (versionType.includes('Einzelplatz'))
        this.configurationProductLevelStore.protocolData.VersionType = 'Einzelplatz'
      else if (versionType.includes('Netzwerk'))
        this.configurationProductLevelStore.protocolData.VersionType = 'Netzwerk'
      else this.configurationProductLevelStore.protocolData.VersionType = 'NULL'

      //got replaced by API
      //await this.configurationProductLevelStore.createProtocol(item.ID, this.userStore.token)
      this.loader = false
      const routeData = this.$router.resolve({ name: 'Configuration Protocol' })
      window.open(routeData.href, '_blank')
    },
    async copyConfiguration(item) {
      this.showModalCopyConfig = false
      Swal.fire({
        icon: 'question',
        title: this.$t('configuration'),
        html: this.$t('useThisConfigurationText').replace('%name%', item.Info),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then(async (result) => {
        if (!(await result.isConfirmed)) return

        var obj = this.configurationProductLevelStore.activeProduct
        obj.Modified = this.companyStore.getNow()
        obj.ModifiedBy = this.userStore.userData.ID
        obj.ConfigurationID = item.ConfigurationID

        var res = await this.configurationProductLevelStore.updateCompanyHasProduct(obj, this.userStore.token)

        if ((res.status = 200)) {
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            html: this.$t('successUseConfiguration'),
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: `${this.$t('errorUseConfiguration')} ${res.data.Message}`,
          })
        }

        // company has products update
        var getChP = await this.companyStore.getProductsByCompanyID(this.companyStore.company.ID, this.userStore.token)
        if (getChP.status === 200) {
          this.companyStore.productData.companyHasProduct = getChP.data
          this.companyHasProducts = getChP.data
          await this.getProductConfigurations()

          var resConfig = await this.configurationProductLevelStore.getConfiguration(
            obj.ConfigurationID,
            this.userStore.token,
          )
          if (resConfig.status === 200) {
            this.handleShow(resConfig.data[0])
          } else {
            Swal.fire({
              icon: 'error',
              title: this.$t('error'),
            })
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: this.$t('error'),
            html: getChP.data.Message,
          }).then((result) => {
            this.$router.push({ name: 'Company' })
          })
        }
      })
    },

    async handleCopy() {
      var prodIDs = []
      this.companyHasProducts.forEach((item) => {
        if (!prodIDs.includes(item.ProductID)) prodIDs.push(item.ProductID)
      })
      await this.getProductNames(prodIDs)

      this.showModalCopyConfig = true
    },
    async handleEdit(item) {
      this.selectedConfiguration = null
      var all = null

      if (
        this.configurationProductLevelStore.checkUsageOfConfiguration(item.ID) &&
        this.configurationProductLevelStore.activeProduct.ConfigurationID !== 'NULL'
      ) {
        all = await Swal.fire({
          icon: 'question',
          title: this.$t('configuration'),
          html: this.$t('updateAllConfigsText'),
          showDenyButton: true,
          showCancelButton: true,
          reverseButtons: true,
          customClass: {
            confirmButton: 'md-simple',
            denyButton: 'md-simple',
            cancelButton: 'md-primary',
          },
          cancelButtonText: this.$t('cancel'),
          denyButtonText: this.$t('all'),
          confirmButtonText: this.$t('onlyThis'),
        }).then(async (result) => {
          await result
          return result
        })
      }
      if (all !== null) {
        if (all.isDismissed) return
        else if (all.isConfirmed) this.configurationProductLevelStore.updateAllConfigs = false
        else if (all.isDenied) this.configurationProductLevelStore.updateAllConfigs = true
      }
      this.loader = true
      this.configurationProductLevelStore.edit = true
      var res = await this.configurationProductLevelStore.createMdesignConfigurationModel(item.ID, this.userStore.token)
      this.configurationProductLevelStore.configurationModel.valid = true
      this.$router.push({ name: 'Mdesign Configuration Manager' })
      this.loader = false
    },
    async handleDelete(configuration) {
      if (this.configurationProductLevelStore.checkUsageOfConfiguration(configuration.ID)) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('configIsUsedTitle'),
          html: this.$t('configIsUsedText'),
        })
        return
      }

      var result = await Swal.fire({
        position: 'middle-end',
        icon: 'warning',
        title: this.$t('askDeleteConfiguration'),
        html: configuration.Info,
        showConfirmButton: true,
        showCancelButton: true,
        allowOutsideClick: false,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })
      if (!result.isConfirmed) return
      else {
        var sure = await Swal.fire({
          position: 'middle-end',
          icon: 'warning',
          title: this.$t('deleteTitle'),
          html: this.$t('deleteText'),
          showConfirmButton: true,
          showCancelButton: true,
          allowOutsideClick: false,
          cancelButtonText: this.$t('cancel'),
        }).then((result) => {
          return result
        })
        if (!sure.isConfirmed) return
        this.loader = true

        await this.deleteHistoryByConfig(configuration)

        await this.getProductConfigurations()
        await this.getHistory()
        await this.getCreatorNames()

        this.loader = false
      }
    },
    async deleteHistoryByConfig(configuration) {
      var resDelHis = await this.configurationStore.deleteConfigurationHistoryByConfiguration(
        configuration.ID,
        this.userStore.token,
      )
      if (resDelHis.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('deleteHistoryTitle'),
          html: this.$t('deleteHistoryText') + ' ' + resDelHis.data.Message,
        })
        return
      }
      var resDelConfig = await this.configurationProductLevelStore.deleteCompleteConfiguration(
        configuration.ID,
        this.userStore.token,
      )
      if (resDelConfig.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('deleteConfigurationTitle'),
          html: this.$t('deleteConfigurationText'),
        })
        return
      }
      Swal.fire({
        icon: 'success',
        title: this.$t('success'),
        html: this.$t('deleteHistorySuccess'),
      })
    },
    async toggleActive(hostFile) {
      var string = ''

      if (hostFile.Disable) string = this.$t('askActivateHostID')
      else string = this.$t('askDeactivateHostID')

      var result = await Swal.fire({
        title: string,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })
      if (result.isConfirmed) {
        this.loader = true
        hostFile.Disable = !hostFile.Disable
        var resp = await this.configurationProductLevelStore.updateConfigurationLicence(hostFile, this.userStore.token)
        if (resp.status !== 200) {
          this.configurationProductLevelStore.fireAnyError(this.$t('error'), resp.statusText)
        } else {
          var text = hostFile.Disable ? this.$t('deactivated') : this.$t('activated')
          Swal.fire({
            icon: 'success',
            title: this.$t('success'),
            html: this.$t('toogleLicFilesuccess').replace('%text%', text),
          })
        }
        this.loader = false
      }
    },
    async getLastCompHasProdAndActivate() {
      let copyCompanyHasProduct = JSON.parse(JSON.stringify(this.companyStore.productData.companyHasProduct))

      let mostRecentObject = copyCompanyHasProduct
        .filter((obj) => obj.Disable === true)
        .reduce((latest, current) => {
          return new Date(current.Created) > new Date(latest.Created) ? current : latest
        }, copyCompanyHasProduct[0])

      mostRecentObject.Disable = false

      var resUpdateCompHasProd = await this.configurationProductLevelStore.updateCompanyHasProduct(
        mostRecentObject,
        this.userStore.token,
      )

      if (resUpdateCompHasProd.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: this.$t('errorActivateProduct'),
        })
        return
      }
      await this.configurationProductLevelStore.updateConfigurationLicences(mostRecentObject.ID, this.userStore.token)
    },
    async getProductNames(idArray) {
      const promises = idArray.map(async (ref) => {
        try {
          var resProd = await this.userStore.getProductDetailsByID(ref)

          if (resProd.status !== 200) return

          this.productNames[ref] = resProd.data[0].Name
        } catch (error) {
          // console.log('error Product ', p.ID, error)
          return false
        }
      })

      await Promise.all(promises)
    },
    handleEditHost(hostFile) {
      this.configurationProductLevelStore.licenceCreatorModel = hostFile
      this.$router.push({ name: 'Mdesign Licence Creator' })
    },
    async handleEditInfo() {
      var initInput = this.activeCompanyHasProduct.Info
      const result = await Swal.fire({
        title: this.$t('putInProductInfoTilte'),
        input: 'text',
        inputLabel: this.$t('putInProductInfo'),
        inputValue: initInput,
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then((result) => {
        return result
      })
      if (!result.isConfirmed) return
      var obj = this.activeCompanyHasProduct
      obj.Info = result.value
      var res = await this.configurationProductLevelStore.updateCompanyHasProduct(obj, this.userStore.token)
      if (res.status === 200) {
        Swal.fire({
          icon: 'success',
          title: this.$t('success'),
          html: this.$t('successSaveInfo'),
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.data.Message,
        })
      }
    },
    handleCreateHost() {
      this.configurationProductLevelStore.licenceCreatorModel.ID = ''
      this.$router.push({ name: 'Mdesign Licence Creator' })
    },
    async handleShow(configuration) {
      this.loader = true
      var res = await this.configurationProductLevelStore.createMdesignConfigurationModel(
        configuration.ID,
        this.userStore.token,
      )
      this.selectedConfiguration = configuration
      this.loader = false
    },
    handleCreate() {
      this.showModalConfig = true
    },
    async handleSendLicense(hostFile) {
      const i18n = this.$i18n
      // Fetch the translations for different locales
      const englishTemplate = i18n.t('sendLicenseMailBody_v86', 'en-US')
      const germanTemplate = i18n.t('sendLicenseMailBody_v86', 'de-DE')

      let email = hostFile.Email !== '' ? hostFile.Email : ''

      const { value: formValues } = await Swal.fire({
        title: this.$t('emailInformation'),
        html: `
              <div class="email-popup">
                <div style="display:flex;flex-direction:column;">
                  <label for="receiver-input">${this.$t('receiver')}</label>
                  <input type="email" id="receiver-input" class="swal2-input" placeholder="Max.Mustermann@domain.de">
                </div>
                <div style="display:flex;flex-direction:column;">
                  <label for="cc-input">Cc (optional)</label>
                <input id="cc-input" class="swal2-input" placeholder="Max.Mustermann@domain.de">
                </div>
                <div style="display:flex;flex-direction:column;">
                  <label for="email-lang-input">${this.$t('lang')}</label>
                  <select value="default" name="email-lang-input" id="email-lang-input" class="swal2-select">
                    <option value="de-DE">${this.$t('german')}</option>
                    <option value="en-US">${this.$t('english')}</option>
                  </select>
                </div>
                <div style="display:flex;flex-direction:column;">
                  <label for="subject-input">${this.$t('subject')}</label>
                  <input :key="this.renderKey" type="text" id="subject-input" class="swal2-input">
                </div>
              </div>
              `,
        focusConfirm: false,
        didOpen: () => {
          const i18n = this.$i18n
          document.getElementById('receiver-input').value = email
          document.getElementById('email-lang-input').value = 'de-DE'
          document.getElementById('subject-input').value = i18n.t('emailInputSubject', 'de-DE')
          document.getElementById('email-lang-input').addEventListener('change', (event) => {
            document.getElementById('subject-input').value =
              event.target.value === 'de-DE'
                ? i18n.t('emailInputSubject', 'de-DE')
                : i18n.t('emailInputSubject', 'en-US')
          })
        },
        preConfirm: async () => {
          const email = document.getElementById('receiver-input').value
          const cc = document.getElementById('cc-input').value

          const emailValidation = await validate(email, { required: true, email: true })
          const ccValidation = await validate(cc, { email: true })
          if (!emailValidation.valid) {
            Swal.showValidationMessage(this.$t('invalidEmail'))
            return null
          }

          if (cc && !ccValidation.valid) {
            Swal.showValidationMessage(this.$t('invalidCcEmail'))
            return null
          }

          return [
            document.getElementById('receiver-input').value,
            document.getElementById('cc-input').value,
            document.getElementById('email-lang-input').value,
            document.getElementById('subject-input').value,
          ]
        },
      })
      if (!formValues) return

      email = formValues[0]
      const cc = formValues[1]
      const lang = formValues[2]
      const subject = formValues[3]
      if (!email) return
      this.loader = true
      var response = await this.getLicence(hostFile.ID, this.activeConfigurationID)
      if (response.status !== 200) return
      const blob = new Blob([response.data], { type: 'text/lic' })
      var file = new File([blob], 'foo.txt', { type: 'text/lic' })
      const resEmail = await sendEmailWithLicense(
        email,
        file,
        hostFile.Version,
        'mdesign_general_2024.lic',
        hostFile.Comment,
        cc,
        lang,
        subject,
      )
      if (resEmail === 'Email is sended.')
        Swal.fire({
          icon: `success`,
          title: this.$t('success'),
          html: this.$t('emailSentOnly'),
        })
      else
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: resEmail,
        })
      this.loader = false
    },
    toTemplates() {
      this.configurationProductLevelStore.resetConfigurationModel()
      this.$router.push({ name: 'Mdesign Configuration Select' })
    },
    toProducts() {
      this.configurationProductLevelStore.resetConfigurationModel()
      this.$router.push({ name: 'Mdesign Configuration Add Product' })
    },
    closeModal() {
      this.showModalConfig = false
      this.showModalCopyConfig = false
      this.showModalDescription = false
      this.infoText = ''
      this.infoName = ''
      this.configUpdate = {}
    },
    toProfile() {
      this.$router.push({ name: 'Company' })
    },
    async getLicence(hostFileID, confiogurationID) {
      // var idFromTable = this.activeCompanyHasProduct.ID + '\\\\' + hostFileID
      // var tableName = 'company_has_product'

      // var resDataFile = await this.companyStore.getFileReferenceByTable(idFromTable, tableName, this.userStore.token)

      // if (resDataFile.status === 200) {
      //   var resFile = await this.companyStore.getDownloadFile(
      //     resDataFile.data[0].DataFileID,
      //     false,
      //     this.userStore.token,
      //   )
      //   this.licFileData = resFile.data

      //   return resFile
      // } else {
      const version = this.configurationProductLevelStore.product.Version
      const versionType = this.configurationProductLevelStore.product.Title.includes('Einzelplatz') ? 'Einzelplatz' : 'Netzwerk'
      var response = await this.configurationProductLevelStore.getLicenceByCombi(
        hostFileID,
        confiogurationID,
        version,
        versionType,
        this.userStore.token,
      )

      if (response?.status === 200) {
        this.licFileData = new Blob([response.data], { type: 'text/lic' }) //response.data
      } else
        Swal.fire({
          position: 'middle-end',
          icon: 'error',
          title: this.$t('errorLicFile'),
          html: response.statusText,
          showConfirmButton: true,
          allowOutsideClick: false,
        })
      return response
      // }
    },
    getFileName(version) {
      var year = '2024'
      switch (version) {
        case 17:
          year = '2018'
          break
        case 18:
          year = '2020'
          break
        case 19:
          year = '2022'
          break
      }
      var fileName = `mdesign_general_${year}.lic`
      return fileName
    },
    async downloadLicence(filename) {
      const blob = this.licFileData // new Blob([this.licFileData], { type: 'text/plain' })
      // Create a blob URL
      const blobUrl = URL.createObjectURL(blob)
      // Create an anchor element
      const link = document.createElement('a')
      // Set the href attribute to the blob URL
      link.href = blobUrl
      // Specify the download attribute and the desired file name
      link.download = filename // Replace 'yourFileName.ext' with the desired file name
      // Append the link to the body (you can also append it to another element)
      document.body.appendChild(link)
      // Trigger a click on the link to start the download
      link.click()
      // Remove the link from the DOM
      document.body.removeChild(link)
      // Revoke the blob URL to free up resources
      URL.revokeObjectURL(blobUrl)
    },
    async getProductConfigurations() {
      this.loader = true

      // reset present data
      this.configurations = []
      this.selectedConfiguration = null

      // get all company_has_product
      await this.getCompanyHasProduct()
      this.configurationProductLevelStore.activeProduct = this.companyStore.productData.companyHasProduct.find(
        (item) => item.ID === this.configurationProductLevelStore.product.CompanyHasProductID,
      )
      // get Host ID Files by Company Has Product ID
      await this.getHostIDFiles()

      // get the active Configuration for this Product
      await this.getActiveConiguration()

      // sort the configurations by created date
      this.sortByCreated()
      this.loader = false
    },
    async getCompanyHasProduct() {
      var res = await this.companyStore.getProductsByCompanyID(this.companyStore.company.ID, this.userStore.token)
      if (res.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.data.Message,
        }).then(() => {
          this.$router.push({ name: 'Company' })
        })
      }
    },
    async getHostIDFiles() {
      var responseHostID = await this.configurationProductLevelStore.getConfigurationLicenceCompanyHasProductID(
        this.configurationProductLevelStore?.activeProduct?.ID,
        this.userStore.token,
      )
      if (responseHostID.status !== 200 && responseHostID.status !== 404) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          htmle: responseHostID.data.Message,
        })
        return
      }
      this.hostFiles = this.configurationProductLevelStore.hostIDFiles
    },
    async getActiveConiguration() {
      if (this.configurationProductLevelStore.activeProduct.ConfigurationID === 'NULL') return
      var res = await this.configurationProductLevelStore.getConfiguration(
        this.configurationProductLevelStore.activeProduct.ConfigurationID,
        this.userStore.token,
      )
      if (res.status === 200) {
        this.selectedConfiguration = res.data[0]
        this.selectedConfiguration.isActive = true
        this.configurations.push(res.data[0])
      } else if (res.status !== 404) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: res.data.Message,
        })
      }
    },
    async getCreatorNames() {
      await Promise.all(
        this.configurations.map(async (con) => {
          var res = await this.companyStore.getContactByID(con.CreatedBy, this.userStore.token)
          this.configTooltips[con.ID] = `${this.$t('createdBy')}: ${res.data[0].FirstName} ${res.data[0].LastName}`
        }),
      )
    },
    sortByCreated(a, b) {
      this.configurations.sort((a, b) => {
        return new Date(b.Created) - new Date(a.Created)
      })
    },
    formatDate(date) {
      const [year, month, day] = date.split('-')
      const formattedDate = `${day.padStart(2, '0')}.${month.padStart(2, '0')}.${year}`
      return formattedDate
    },
    async handleDownloadLicence(hostFile) {
      //console.log(hostFile)

      Swal.fire({
        icon: 'question',
        title: this.$t('downloadLicTitle'),
        html: this.$t('downloadLicText').replace('%text%', hostFile.Comment),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then(async (result) => {
        if (!(await result).isConfirmed) return
        this.loader = true
        if (!hostFile.ID || !this.activeConfigurationID) return
        var resLic = await this.getLicence(hostFile.ID, this.activeConfigurationID)

        if (resLic.status === 200) {
          const contentDisposition = resLic.headers['content-disposition'] || resLic.headers['Content-Disposition']
          const version = this.configurationProductLevelStore.product.Version
          let filename = this.getFileName(version)
          if (contentDisposition) {
            const filenameMatch = contentDisposition.match(/filename=([^;]+)/i)
            if (filenameMatch.length > 1) {
              filename = filenameMatch[1]
            }
          }
          await this.downloadLicence(filename)
        }
        this.loader = false
      })
    },
    getConfigInfoName(config) {
      if (config) return config.Info.includes('/*\\') ? config.Info.split('/*\\')[0] : ''
      else return ''
    },
    getConfigInfo(config) {
      if (config) return config.Info.includes('/*\\') ? config.Info.split('/*\\')[1] : config.Info
      else return ''
    },
    getUsableConfigs(productIDsByVersion) {
      const productIDsSet = new Set(productIDsByVersion.map((product) => product.ID))
      this.usableConfigs = this.companyHasProducts.filter(
        (item) => item.ConfigurationID !== 'NULL' && productIDsSet.has(item.ProductID),
      )
    },
    async onFileChange(e) {
      this.dragging = false
      let files = e.target.files || e.dataTransfer.files
      //if (!files.length) return
      

      const token = this.userStore.token
      const contactID = this.userStore.userData.ID
      const companyID = this.activeCompanyHasProduct.CompanyID
      const companyHasProductID = this.activeCompanyHasProduct.ID
      const file = files[0]
      this.loader = true
      const resUploadLicense = await this.configurationProductLevelStore.uploadLicenceFilesForConfiguration(
        contactID,
        companyID,
        companyHasProductID,
        file,
        token,
      )
      if(resUploadLicense.status !== 200) {
        Swal.fire({
          icon: 'error',
          title: this.$t('error'),
          html: resUploadLicense.Message,
        })
      } else {
        Swal.fire({
          icon: 'success',
          title: this.$t('success')
        })
      }
      this.loader = false
    },
    readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result) // Resolve the promise with the result
        }
        reader.onerror = reject // Reject the promise on error
        reader.readAsText(file) // Start reading the file
      })
    },
    handleDragOver(event) {
      event.preventDefault()
      this.dragging = true
    },
    handleDragLeave() {
      this.dragging = false
    },
  },
  computed: {
    getTooLong() {
      return this.infoText.length + this.infoName.length + 3 > 255 ? true : false
    },
  },
  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationProductLevelStore = useConfigurationProductLevelStore()
    const configurationStore = useConfigurationStore()
    const managementStore = useManagementStore()
    return { companyStore, userStore, configurationProductLevelStore, configurationStore, managementStore }
  },
}
</script>

<style scoped lang="scss">
@media (max-width: 600px) {
  .col-container {
    flex: 2;
  }
  .col-info {
    flex: 0 0 100px;
  }
  .col-date {
    padding-left: 20px;
  }
  .col-header {
    font-size: 17px !important;
    padding-bottom: 12px;
  }
  .btn-container {
    display: flex;
    flex-direction: column;
  }
}
@media (min-width: 601px) {
  .col-container {
    flex: 2;
  }
  .col-info {
    flex: 0 0 200px;
  }
  .col-date {
    padding-left: 20px;
  }
  .col-header {
    font-size: 17px !important;
    padding-bottom: 12px;
  }
}

@media (min-width: 961px) {
  .col-container {
    flex: 2;
  }
  .col-info {
    flex: 0 0 200px;
  }
  .col-date {
    padding-left: 20px;
  }
  .col-header {
    font-size: 17px !important;
    padding-bottom: 12px;
  }
}

@media (min-width: 1501px) {
  .col-container {
    flex: 2;
  }
  .col-info {
    flex: 0 0 450px;
  }
  .col-header {
    font-size: 17px !important;
    padding-bottom: 12px;
  }
}

.md-checkbox {
  margin: unset !important;
}
.md-center {
  text-align: center;
}

.md-simple-no-hover {
  .md-button-content {
    display: flex;
    align-items: center;
    // background: transparent;

    i {
      font-size: 14px !important;
    }
  }
}
.md-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.md-simple-no-hover {
  background: transparent !important;
  box-shadow: none !important;
}

@keyframes changeGradient {
  /* Start from the left */
  0% {
    background-position: -100% 0;
  }

  /* Move to the right */
  100% {
    background-position: 100% 0;
  }
}
.md-field .animated-icon {
  visibility: hidden;
  padding-bottom: 0;
}
.md-field {
  margin: 0;
}
.md-field:hover .animated-icon {
  visibility: visible;
}
.controls {
  display: flex;
  flex-direction: row;
  padding-bottom: 4px;
  padding-right: 4px;
  justify-content: flex-end;
}
.md-layout-item {
  padding: 0;
}
.icon-container {
  position: relative;
}
.config-content {
  display: flex;
}

.md-custom-field {
  padding-bottom: 0 !important;
}
.md-custom-field:after {
  bottom: 0;
}
.info-content {
  display: flex;
  flex-direction: row;
}
.pl-15 {
  padding-left: 15px;
}
.pt-15 {
  padding-top: 15px;
}
.mt-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.fl-col {
  display: flex;
  flex-direction: column;
}
.config-info {
  font-size: 12px;
}
.just-center {
  display: flex;
  justify-content: center;
}
.pr-10 {
  padding-right: 10px;
}
.modal-span {
  width: 100%;
  text-align: left;
}
.mh-34-5 {
  min-height: 34.5px !important;
}
textarea {
  padding-left: 0 !important;
}
.config-info {
  padding-left: 5px;
}
.space-evenly {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}
.pb-10 {
  padding-bottom: 10px;
}
.just-right {
  display: flex;
  padding-top: 1rem;
  justify-content: right !important;
}
</style>
