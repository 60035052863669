<template>
  <div class="md-layout-item md-size-66 md-medium-size-66 md-small-size-100 md-xsmall-size-100 center-located">
    <ValidationObserver v-slot="{ handleSubmit, invalid }">
      <form @submit.prevent="handleSubmit(submit)">
        <loader :loader="loader" />
        <md-card class="card-content">
          <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
            <div class="card-icon">
              <md-icon>cloud</md-icon>
            </div>
            <h4 class="title">
              {{ $t('awsAccountData') }}
            </h4>
          </md-card-header>
          <md-card-content>
            <div class="md-layout">
              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('customerNumber') }}</label>
                  <md-input v-model="company.CustomerNr" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label for="configType">{{ $t('accountType') }}</label>
                  <md-select
                    v-model="configurationAws.ConfigurationAccountTypeID"
                    name="configType"
                    id="configType"
                    disabled
                  >
                    <md-option
                      v-for="configType in configurationAccountTypes"
                      :value="configType.ID"
                      :key="configType.ID"
                    >
                      {{ configType.Name }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label for="runtimeinmonth">{{ $t('runtime') }}</label>
                  <md-select
                    v-model="configurationAws.RuntimeInMonth"
                    name="runtimeinmonth"
                    id="runtimeinmonth"
                    :disabled="expired"
                  >
                    <md-option v-for="runtime in runtimes" :value="runtime.value" :key="runtime.value">
                      {{ runtime.label }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label>{{ $t('agency') }}</label>
                  <md-input v-model="creator" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field>
                  <label for="stack">{{ $t('stackInformation') }}</label>
                  <md-select v-model="configurationAws.FleetNormal" name="stack" id="stack" disabled>
                    <md-option v-for="stack in stacks" :value="stack.Stacks" :key="stack.Stacks">
                      {{ stack.Description }}
                    </md-option>
                  </md-select>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <ValidationProvider name="countCustomer" rules="required|integer" v-slot="{ passed, failed }">
                  <md-field :class="[{ 'md-error': failed }, { 'md-valid': passed }]">
                    <label>{{ $t('countCustomer') }}</label>
                    <md-input type="number" v-model="configurationAws.Count" :disabled="expired"></md-input>
                    <slide-y-down-transition>
                      <md-icon class="error" v-show="failed">close</md-icon>
                    </slide-y-down-transition>
                    <slide-y-down-transition>
                      <md-icon class="success" v-show="passed">done</md-icon>
                    </slide-y-down-transition>
                  </md-field>
                </ValidationProvider>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field v-if="!expired">
                  <label>{{ $t('startDate') }}</label>
                  <md-input type="date" v-model="configurationAws.StartDate" disabled />
                </md-field>

                <md-field v-else>
                  <label>{{ $t('startDate') }}</label>
                  <md-input v-model="configurationAws.StartDate" type="text" disabled></md-input>
                </md-field>
              </div>

              <div class="md-layout-item md-small-size-100 md-size-50">
                <md-field v-if="!expired">
                  <label>{{ $t('endDate') }}</label>
                  <md-input type="date" v-model="configurationAws.EndDate" disabled />
                </md-field>

                <md-field v-else>
                  <label>{{ $t('endDate') }}</label>
                  <md-input v-model="configurationAws.EndDate" type="text" disabled></md-input>
                </md-field>
              </div>
            </div>
            <div class="md-layout">
              <div class="md-layout-item md-size-100 text-right">
                <md-button
                  v-if="!expired"
                  id="submit"
                  class="md-raised md-primary mt-4 md-round"
                  @click="askUpdate()"
                  :disabled="invalid"
                  >{{ $t('save') }}
                </md-button>
              </div>
            </div>
          </md-card-content>
        </md-card>
      </form>
    </ValidationObserver>

    <div class="spacer"></div>

    <md-card v-if="!editAwsUsers" class="card-content" :key="renderKey">
      <md-card-header class="md-card-header-icon" :class="getClass(headerColor)">
        <div class="card-icon">
          <md-icon>people_outline</md-icon>
        </div>
        <h4 class="title">
          {{ $t('awsAccesses') }}
        </h4>
      </md-card-header>
      <md-card-content>
        <md-table
          :value="queriedData"
          :md-sort.sync="currentSort"
          :md-sort-order.sync="currentSortOrder"
          :md-sort-fn="customSort"
          class="paginated-table table-striped table-hover"
        >
          <md-table-toolbar>
            <div class="toolbar-left">
              <md-field>
                <div>
                  <label for="pages" id="perPage">{{ $t('perPage') }}</label>
                </div>
                <md-select v-model="pagination.perPage" name="pages">
                  <md-option v-for="item in pagination.perPageOptions" :key="item" :label="item" :value="item">
                    {{ item }}
                  </md-option>
                </md-select>
              </md-field>
              <md-field class="ml-10">
                <md-input
                  type="search"
                  class="mb-3"
                  clearable
                  style="width: 200px"
                  :placeholder="searchRecords"
                  v-model="searchQuery"
                ></md-input>
              </md-field>
            </div>
          </md-table-toolbar>

          <md-table-row slot="md-table-row" slot-scope="{ item, index }">
            <md-table-cell :md-label="'#'">
              {{ calculatePosition(index) }}
            </md-table-cell>

            <md-table-cell :md-label="$t('name')" md-sort-by="LastName"
              >{{ item.FirstName }}<br /><strong>{{ item.LastName }}</strong></md-table-cell
            >

            <md-table-cell :md-label="$t('email')" md-sort-by="Email">{{ item.Email }}</md-table-cell>

            <md-table-cell :md-label="$t('actions')">
              <md-switch v-model="item.IsActive" @change="handleAwsAccess(item)" :disabled="expired">
                {{ $t('accessActive') }}
              </md-switch>
            </md-table-cell>
          </md-table-row>
        </md-table>
      </md-card-content>

      <md-card-actions>
        <div class="card-actions-container">
          <div class="button-actions">
            <md-button id="submit" class="md-raised md-primary md-round" @click="toggleEditUsers()" :disabled="expired">
              {{ $t('contacts') }}
            </md-button>
          </div>
          <div class="table-actions">
            <div class="">
              <p class="card-category">
                {{ $t('show') }} {{ from + 1 }} {{ $t('to') }} {{ to }} {{ $t('of') }} {{ total }} {{ $t('entries') }}
              </p>
            </div>

            <pagination
              class="pagination-no-border pagination-success"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></pagination>
          </div>
        </div>
      </md-card-actions>
    </md-card>
    <editAwsUsers
      v-if="editAwsUsers"
      @updateAwsList="updateAwsList"
      :awsIds="awsIDArray"
      :configAWSID="this.configAWSID"
      :companyID="this.company.ID"
      :contacts="this.allContacts"
      :configurationAws="this.configurationAws"
    ></editAwsUsers>
  </div>
</template>

<script>
import { Pagination } from '@/components'
import Fuse from 'fuse.js'
import { extend } from 'vee-validate'
import { required, min_value, email, numeric, integer } from 'vee-validate/dist/rules'
// import SelectCountry from "../../components/SelectCountry/SelectCountry.vue";
import { useCompanyStore } from '@/stores/company'
import { useUserStore } from '../../stores/user'
import { useConfigurationStore } from '../../stores/configuration'
import Swal from 'sweetalert2'
import EditAwsUsers from '@/pages/AwsAccount/EditAwsUsers.vue'

extend('required', required)
extend('min_value', min_value)
extend('email', email)
extend('numeric', numeric)
extend('integer', integer)

export default {
  components: { Pagination, EditAwsUsers }, //SelectCountry
  name: 'edit-contact-form',
  props: {
    headerColor: {
      type: String,
      default: 'primary',
    },
  },

  setup() {
    const companyStore = useCompanyStore()
    const userStore = useUserStore()
    const configurationStore = useConfigurationStore()
    return { companyStore, userStore, configurationStore }
  },

  data() {
    return {
      productIDCloud: '{faeb2319-1fb3-4fb8-a5ea-d3250f5ccccd}',
      configAWSID: '',
      awsIDArray: [],
      editAwsUsers: false,
      currentSort: 'LastName',
      currentSortOrder: 'asc',
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
        active: false,
      },
      searchQuery: '',
      tableData: [],
      allContacts: [],
      contactsWithAws: [],
      searchedData: [],
      fuseSearch: null,
      searchRecords: this.$t('searchRecords'),
      perPage: this.$t('perPage'),
      renderKey: 1,
      userFilter: 1,
      company: {},
      configurationAws: {},
      creator: '',
      stacks: [],
      configurationAccountTypes: [{}],
      runtimes: [],
      expired: null,
      loader: false,
    }
  },
  async beforeMount() {
    this.loader = true
    this.company = this.companyStore.company
    const compID = this.companyStore.company.ID
    var responseAllContacts = await this.companyStore.getContactsByCompanyID(compID)
    if (responseAllContacts.status !== 404) this.allContacts = responseAllContacts.data
    else this.allContacts = []
    if (responseAllContacts.status !== 200 && responseAllContacts.status !== 404) {
      Swal.fire({
        title: this.$t('errorLoadContacts'),
        text: responseAllContacts.statusText,
        type: 'warning',
        confirmButtonClass: 'md-button md-success btn-fill',
        buttonsStyling: false,
      }).then((result) => {
        this.loader = false
        this.$router.push({ name: 'Company Products' })
      })
    } else {
      this.searchedData = this.tableData
    }

    this.configurationAws = this.configurationStore.configuration
    this.stacks = this.configurationStore.awsStacks
    const resCreator = await this.companyStore.getContactByID(this.configurationAws.CreatedBy, this.userStore.token)
    const creator = resCreator.data[0]
    this.creator = `${creator.FirstName} ${creator.LastName}`

    this.configurationAccountTypes = this.configurationStore.configurationAccountTypes

    // this.expired = new Date() > new Date(this.configurationAWS.EndDate.split('T')[0])
    this.configurationAws.StartDate = this.configurationAws.StartDate.includes('T')
      ? this.configurationAws.StartDate.split('T')[0]
      : this.configurationAws.StartDate
    this.configurationAws.EndDate = this.configurationAws.EndDate.includes('T')
      ? this.configurationAws.EndDate.split('T')[0]
      : this.configurationAws.EndDate

    this.configAWSID = this.configurationStore.configuration.ID
    await this.getAWSUsers(this.configAWSID)

    // this.configurationAWS.EndDate = this.configurationAWS.EndDate.includes('T') ? this.configurationAWS.EndDate.split('T')[0] : undefined
    // this.configurationAWS.StartDate = this.configurationAWS.StartDate.includes('T') ? this.configurationAWS.StartDate.split('T')[0] : undefined
    this.fuseSearch = new Fuse(this.tableData, {
      keys: ['FirstName', 'LastName', 'Email'],
      findAllMatches: true,
      useExtendedSearch: true,
      shouldSort: true,
      ignoreLocation: true,
      threshold: 0.1,
    })
    this.renderKey++

    this.runtimes = [
      {
        value: 0.5,
        label: `2 ${this.$t('weeks')} ${this.$t('testPosition')}`
      },
      {
        value: 1,
        label: '1 ' + this.$t('month'),
      },
      {
        value: 3,
        label: '3 ' + this.$t('months'),
      },
      {
        value: 6,
        label: '6 ' + this.$t('months'),
      },
      {
        value: 12,
        label: '12 ' + this.$t('months'),
      },
    ]
    this.loader = false
  },
  methods: {
    askUpdate() {
      Swal.fire({
        title: this.$t('askSave'),
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonClass: 'md-button md-success btn-fill',
        cancelButtonClass: 'md-button md-danger btn-fill',
        confirmButtonText: this.$t('confirm'),
      }).then((result) => {
        if (result.dismiss) return
        this.updateAwsConfig()
      })
    },
    async updateAwsConfig() {
      this.loader = true
      const token = this.userStore.token
      this.configurationAws.ModifiedBy = this.userStore.userData.ID
      this.configurationAws.Count = parseInt(this.configurationAws.Count)
      const date = new Date()
      this.configurationAws.Modified = this.companyStore.formatDate(date)
      const response = await this.configurationStore.updateConfigurationAws(this.configurationAws, token)
      this.loader = false
      if (response.status === 200) {
        Swal.fire({
          title: this.$t('success'),
          html: this.$t('dataSaved'),
          icon: 'success',
          confirmButtonText: this.$t('ok'),
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      } else {
        Swal.fire({
          title: this.$t('error'),
          html: res.data.Message,
          icon: 'error',
          confirmButtonClass: 'md-button md-success btn-fill',
        })
      }
    },
    calculateEndDate() {
      if (this.configurationAws.StartDate && this.configurationAws.RuntimeInMonth) {
        const startDate = new Date(this.configurationAws.StartDate)
        const runtimeInMonth = this.configurationAws.RuntimeInMonth

        startDate.setMonth(startDate.getMonth() + runtimeInMonth)

        // Update the EndDate property
        this.$set(this.configurationAws, 'EndDate', startDate.toISOString().split('T')[0])
      }
    },
    async getAWSUsers(configurationAWS) {
      this.loader = true
      this.contactsWithAws = []
      this.awsIDArray = []
      const token = this.userStore.token
      const respConfigHasContact = await this.configurationStore.getConfigurationAwsHasContact(configurationAWS, token)
      if (respConfigHasContact !== 200) {
      }
      const contactsWithAws = respConfigHasContact.data
      if (contactsWithAws.length > 0) {
        contactsWithAws.forEach((contact) => {
          var toAdd = this.allContacts.find((item) => item.ID === contact.ContactID)

          if (toAdd !== undefined) {
            var temp = {
              LastName: toAdd.LastName,
              FirstName: toAdd.FirstName,
              Email: toAdd.Email,
              ConfigurationAwsID: contact.ConfigurationAwsID,
              ContactID: toAdd.ID,
              IsActive: contact.IsActive,
              CreatedBy: contact.CreatedBy,
              Created: contact.Created,
              ModifiedBy: contact.ModifiedBy,
              Modified: contact.Modified,
            }

            if (!this.awsIDArray.includes(temp.ContactID)) {
              this.awsIDArray.push(temp.ContactID)
              this.contactsWithAws.push(temp)
            }
          }
        })
      }
      this.tableData = this.contactsWithAws
      this.searchedData = this.tableData
      this.loader = false
    },
    async handleAwsAccess(item, index) {
      var check = this.checkMaxAccess()
      if (!check) {
        item.IsActive = !item.IsActive
        return
      }
      var text = item.IsActive ? this.$t('activate').toLowerCase() : this.$t('deactivate').toLowerCase()
      var text2 = item.IsActive ? this.$t('activated').toLowerCase() : this.$t('deactivated').toLowerCase()
      Swal.fire({
        icon: 'question',
        title: this.$t('awsAccessTitle'),
        html: this.$t('awsAccessText').replace('%text%', text).replace('%email%', item.Email),
        showCancelButton: true,
        cancelButtonText: this.$t('cancel'),
      }).then(async (result) => {
        if (!(await result).isConfirmed) {
          item.IsActive = !item.IsActive
          return
        } else {
          this.loader = true
          const ModifiedBy = this.userStore.userData.ID
          const token = this.userStore.token
          var obj = {
            ConfigurationAwsID: item.ConfigurationAwsID,
            ContactID: item.ContactID,
            IsActive: item.IsActive,
            ModifiedBy: ModifiedBy,
          }

          var res = await this.configurationStore.updateConfigurationAwsHasContact(obj, token)
          if (res.status === 200) {
            Swal.fire({
              icon: 'success',
              title: this.$t('success'),
              html: this.$t('awsAccessSuccess').replace('%text%', text2),
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: this.$t('error'),
              text: res.data.Message,
            })
          }
          this.loader = false
        }
      })
    },
    checkMaxAccess() {
      let count = 0
      this.contactsWithAws.forEach((item) => {
        if (item.IsActive) count++
      })
      if (count > this.configurationAws.Count) {
        Swal.fire({
          icon: 'warning',
          title: this.$t('maxAwsAccessTitle'),
          html: this.$t('maxAwsAccessText'),
        })
        return false
      }
      return true
    },
    toggleEditUsers() {
      this.editAwsUsers = !this.editAwsUsers
    },
    async updateAwsList(configurationAws) {
      await this.getAWSUsers(configurationAws.ID)
      this.toggleEditUsers()
    },
    getClass: function (headerColor) {
      return 'md-card-header-' + headerColor + ''
    },
    handleCountry(val) {
      this.awsCompany.Kommunikationssprache = val.value.toLowerCase()
    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'desc') {
          return a[sortBy].localeCompare(b[sortBy])
        }
        return b[sortBy].localeCompare(a[sortBy])
      })
    },
    calculatePosition(index) {
      return index + 1 + (this.pagination.currentPage - 1) * this.pagination.perPage
    },
    changeTranslation() {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
      this.runtimes = [
        {
          value: 1,
          label: '1 ' + this.$t('month'),
        },
        {
          value: 3,
          label: '3 ' + this.$t('months'),
        },
        {
          value: 6,
          label: '6 ' + this.$t('months'),
        },
        {
          value: 12,
          label: '12 ' + this.$t('months'),
        },
      ]
      this.renderKey += 1
    },
    isEmptyObject(obj) {
      return Object.keys(obj).length === 0
    },
  },
  computed: {
    // return userStore
    // }
    /***
     * Returns a page from the searched data or the whole data. Search is performed in the watch section below
     */
    queriedData() {
      let result = this.tableData
      result = this.searchedData
      return result.slice(this.from, this.to)
    },

    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchedData.length
    },
  },
  watch: {
    'configurationAws.StartDate': 'calculateEndDate',
    'configurationAws.RuntimeInMonth': 'calculateEndDate',
    searchQuery(value) {
      let result = this.tableData
      if (value !== '') {
        result = this.fuseSearch.search(this.searchQuery)
        result = result.map((record) => record.item)
      }
      this.searchedData = result
    },
    perPage() {
      this.renderKey++
    },
  },
  updated() {
    if (this.perPage != this.$t('perPage')) {
      this.perPage = this.$t('perPage')
      this.searchRecords = this.$t('searchRecords')
    }
  },
}
</script>

<style lang="css" scoped>
@media (max-width: 500px) {
  .toolbar-left {
    flex-direction: column;
  }
}

@media (min-width: 501px) {
  .toolbar-left {
    display: flex;
    flex-direction: row;
  }

  .ml-10 {
    margin-left: 10px;
  }
}

.md-card {
  width: 98%;
  margin: 0 auto;
}

.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}

.md-table-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}

.spacer {
  height: 4rem;
}

.center-located {
  margin: 0 auto;
}
/* WebKit browsers (Chrome, Safari, Edge) */

.card-actions-container {
  width: 100%;
}
.table-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.button-actions {
  display: flex;
  justify-content: flex-end;
  padding: 12px 0;
}
</style>
